import axios from "axios";

const BASE_URL = "https://www.questco2-api.fullstack.name.ng"

export default axios.create({
    baseURL: BASE_URL
});

export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: {"Content-Type": "application/json"},
    withCredentials: true    
})
